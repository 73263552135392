<script>
/**
 * v-filters
 * SLOTS:
 * - left: for all buttons and functional elements not related to search on the left side
 * - search: for the single multi-search input field, always visible
 * - right: right side slot between "Filter trigger" and "Show predefined filters" dropdown
 * - default: all filtering fields for enableFilters: true. This is already under "row" class!
 *
 * PROPS:
 * - enableFilters - for ADDITIONAL filters (below the line, with hide/show button)
 * - filterViews - PREDEFINED filter views (object hash {filter_id: filter_name})
 * - filterView - CURRENT filter view (for filterViews)
 *
 * EVENTS:
 * - @reset-search: reset button clicked
 * - @filter-view-change: when a filterView is changed (in dropdown)
 */
import { Dropdown, DropdownMenu, DropdownItem } from "element-ui";
import MGConsts from "@/mixins/MGConsts";

export default {
    name: "VFilters",
    mixins: [MGConsts],
    components: {
        [Dropdown.name]: Dropdown,
        [DropdownMenu.name]: DropdownMenu,
        [DropdownItem.name]: DropdownItem,
    },
    props: {
        search: {
            type: Boolean,
            default: false,
            description: "Enable search field - requires usage of v-model",
        },
        searchPlaceholder: {
            type: String,
            default: "",
            description: "Placeholder for the search field",
        },
        enableFilters: {
            type: Boolean,
            default: true,
            description:
                "SLOT: default. Enables filters that are triggered by the filter button (additional fields).",
        },
        filterViews: {
            description:
                "An object containing {filter_ident: filter_name} used for triggering predefined views",
        },
        filterView: {
            description: "Current filterView in conjuction with filterViews.",
        },
        filterIcon: {
            default: "far fa-list",
        },
        sortViews: {
            description: "Optional sort views",
        },
        paginator: {
            description: "Paginator from reqManager",
        },
        value: {
            description: "v-model",
        },
        startOpen: {
            type: Boolean,
            default: false,
            description: "Start in open state - for situations where one wants v-filter details open on init"
        }
    },
    data() {
        return {
            showFilters: false,
            currentFilterView: "",
            currentSortView: "",
            direction: {
                asc: "sort-amount-up-alt",
                desc: "sort-amount-down-alt",
            },
        };
    },
    watch: {
        filterView: function (v) {
            if (typeof this.filterViews !== "undefined") {
                this.setCurrentFilterView(v);
            }
        },
    },
    computed: {
        sortView: function () {
            return this.paginator.sort;
        },
    },
    created() {
        //console.log("VFILTERS", typeof this.filterViews, this.filterViews);
        // Set filter name of init if in use
        if (typeof this.filterViews !== "undefined") {
            this.setCurrentFilterView(this.filterView);
        }

        if (typeof this.sortViews !== "undefined") {
            this.setCurrentSortView(null);
        }

        if (this.enableFilters && this.startOpen) {
            this.emitFilterStatus();
        }
    },
    methods: {
        setCurrentFilterView(cmd) {
            if (cmd === null) {
                cmd = "null";
            }

            if (Array.isArray(this.filterViews)) {
                this.currentFilterView = this.$matchIn(this.filterViews, cmd);
            } else {
                this.currentFilterView = this.filterViews[cmd];
            }
        },
        handleCommand(cmd) {
            this.setCurrentFilterView(cmd);
            this.$emit("filter-view-change", cmd);
        },

        showCheck(idx) {
            if (
                idx == this.filterView ||
                (idx == "null" && this.filterView === null)
            ) {
                return true;
            } else {
                return false;
            }
        },

        showSortCheck(idx) {
            if (idx == this.sortView) {
                return true;
            } else {
                return false;
            }
        },

        handleSortCommand(cmd) {
            let oldSortView = this.paginator.sort;
            this.setCurrentSortView(cmd);
            if (oldSortView == cmd) {
                this.$emit("sort", {
                    sort: cmd,
                    direction: this.flipDirection(),
                });
            } else {
                this.$emit("sort", {
                    sort: cmd,
                    direction: this.getDirection(),
                });
            }
        },

        getDirection() {
            if (this.paginator.direction == null) {
                return "asc";
            } else {
                return this.paginator.direction;
            }
        },

        flipDirection() {
            if (typeof this.paginator.direction == "null") {
                return "asc";
            } else if (this.paginator.direction == "desc") {
                return "asc";
            } else {
                return "desc";
            }
        },

        setCurrentSortView(cmd) {
            if (cmd === null) {
                cmd = "null";
            }

            if (Array.isArray(this.sortViews)) {
                this.currentSortView = this.$matchIn(this.sortViews, cmd);
            } else {
                this.currentSortView = this.sortViews[cmd];
            }
        },

        reEmit(inp) {
            this.$emit("input", inp);
        },

        emitFilterStatus() {
            this.showFilters = !this.showFilters;
            if (this.showFilters) {
                this.$emit("filters-open");
            } else {
                this.$emit("filters-close");
            }
        },
    },
};
</script>

<template>
    <div class="v-filters">
        <div class="v-filters__container">
            <div class="v-filters__container__left" v-if="$slots.left">
                <slot name="left" />
            </div>
            <div
                :class="{
                    'v-filters__container__search': search,
                    'v-filters__container__search--filters':
                        !search && enableFilters,
                }"
                v-if="search || enableFilters"
            >
                <FormulateInput
                    v-if="search"
                    :value="value"
                    @input="reEmit"
                    type="fsearch"
                    :placeholder="
                        searchPlaceholder != ''
                            ? searchPlaceholder
                            : this.$t('search.placeholder_search')
                    "
                />

                <base-button
                    @click="emitFilterStatus"
                    v-if="enableFilters"
                    style="border: none"
                    type="primary"
                    class="ml-2"
                    :outline="true"
                >
                    <i class="far fa-filter"></i>
                </base-button>

                <transition name="fade">
                    <base-button
                        v-if="showFilters && enableFilters"
                        style="border: none"
                        type="primary"
                        class="ml-2"
                        :outline="true"
                        @click="$emit('reset-search')"
                    >
                        <i class="far fa-sync"></i>
                    </base-button>
                </transition>
            </div>
            <div class="v-filters__container__right">
                <!-- Filter toggle (additional search) -->

                <!-- Right slot changed from span to TEMPLATE -->                
                <template v-if="$slots.right">
                    <slot name="right" />
                </template>

                <!-- filter views -->
                <span v-if="filterViews">
                    <el-dropdown @command="handleCommand">
                        <span class="el-dropdown-link">
                            <i :class="filterIcon"></i>

                            <span class="ml-1 text-default">{{
                                currentFilterView
                            }}</span>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <!-- Hash object {a:b, c:d ...} -->
                            <template v-if="!Array.isArray(this.filterViews)">
                                <el-dropdown-item
                                    v-for="(filter, index) in filterViews"
                                    :key="index"
                                    :command="index"
                                >
                                    {{ filter }}
                                    <i
                                        v-if="showCheck(index)"
                                        class="far fa-check"
                                    ></i>
                                </el-dropdown-item>
                            </template>
                            <!-- Array -> as for select -->
                            <template v-else>
                                <el-dropdown-item
                                    v-for="(filter, index) in filterViews"
                                    :key="index"
                                    :command="filter.value"
                                >
                                    {{ filter.label }}
                                    <i
                                        v-if="showCheck(filter.value)"
                                        class="far fa-check"
                                    ></i>
                                </el-dropdown-item>
                            </template>
                        </el-dropdown-menu>
                    </el-dropdown>
                </span>
                <!-- .end of filterViews -->
                <!-- Start of sortViews -->
                <!-- filter views -->
                <span v-if="sortViews">
                    <el-dropdown @command="handleSortCommand">
                        <span class="el-dropdown-link">
                            <i :class="`fal fa-${direction[getDirection()]}`">
                            </i>
                            <template v-if="currentSortView">
                                <span class="text-default">{{
                                    currentSortView
                                }}</span></template
                            >
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <!-- Hash object {a:b, c:d ...} -->
                            <template v-if="!Array.isArray(this.sortViews)">
                                <el-dropdown-item
                                    v-for="(filter, index) in sortViews"
                                    :key="index"
                                    :command="index"
                                >
                                    {{ filter }}
                                    <i
                                        v-if="showSortCheck(index)"
                                        class="far fa-check"
                                    ></i>
                                </el-dropdown-item>
                            </template>
                            <!-- Array -> as for select -->
                            <template v-else>
                                <el-dropdown-item
                                    v-for="(filter, index) in sortViews"
                                    :key="index"
                                    :command="filter.value"
                                >
                                    {{ filter.label }}
                                    <i
                                        v-if="showSortCheck(filter.value)"
                                        class="far fa-check"
                                    ></i>
                                </el-dropdown-item>
                            </template>
                        </el-dropdown-menu>
                    </el-dropdown>
                </span>
                <!-- .end of sortViews -->
            </div>
        </div>
        <transition name="fade">
            <div
                class="row v-filters__container__filters"
                v-if="showFilters && enableFilters"
            >
                <slot />
            </div>
        </transition>
    </div>
</template>

<style lang="scss">
.v-filters {
    &__container {
        //border: 1px dotted green;
        display: flex;
        flex-direction: row;
        padding-right: 15px;
        align-items: center;
        padding-bottom: 18px;

        &__left {
            //border: 1px dotted black;
            flex-grow: 0;
            margin-right: 15px;
        }

        &__search {
            
            .vf-c {
                width: 340px;
            }

            flex: 1 1 350px; 

            display: flex;
            .form-group {
                margin-bottom: 0px;
            }

            > div {
                width: 100%;
            }
        }

        // Duplicate of the above - though w/o search box!
        &__search--filters {
            //border: 1px solid red;
            display: flex;
            flex: 0 0 80px;

            .form-group {
                margin-bottom: 0px;
            }

            > div {
                width: 100%;
            }
        }

        &__right {
            //border: 1px dotted red;
            flex-grow: 1;
            flex-wrap: wrap;
            justify-content: flex-end;
            display: flex;

            .right__filter-elem {
                display: inline-flex;
                flex: 1 1;
                //border: 1px dotted red !important;
                margin-left: 30px;
            }

            & > span {
                margin-left: 30px;
                font-size: 15px;
            }
        }
    }

    @media (max-width: 991.98px) {
        &__container {
            flex-wrap: wrap;
            padding-right: 0;

            &__left {
                flex: 20%;
            }

            &__search {
                flex: 75%;

                .vf-c {
                    width: 420px;
                }
            }

            &__right {
                flex: 100%;
                margin-top: 20px;
            }
        }
    }

    @media (max-width: 767.98px) {
        &__container {
            // flex-direction: column;
            padding-right: 0;
            align-items: left;

            &__left {
                flex: 100%;
                margin-bottom: 40px;
            }

            &__search {
                flex-grow: 0;
                // min-width: 280px !important;
                flex: 100%;
                .form-group {
                    // margin-bottom: 20px;
                }

                .vf-c {
                    width: auto;
                }
            }

            &__filters {
                .col {
                    flex: 100%;
                }

                .range-picker {
                    margin-bottom: 1.5rem;
                }
            }

            &__right {
                flex-wrap: wrap;

                & > span {
                    margin-left: 0;
                    flex: 100%;
                }

                .right__filter-elem {
                    margin-left: 0;
                    display: block;
                    margin-bottom: 15px;
                }
            }

            &__filters {
                margin-bottom: 30px;
            }
        }
    }
}
</style>